import React , { useEffect } from 'react'; 
import { Link } from 'react-router-dom'; 

import './Oops.scss'
import ooPS from '../../assets/oops.png'


    export const Oops = () => {
      useEffect(() => {
        document.title = 'PAGE NOT FOUND'; 
      }, []);
        return (
          <>
        <section className="oops " id="home">
    <div className='effectImg'></div>
        <div className="oops__container container ">
            <div className="oops__content">
            
            <div className="oops__img">
                <img src={ooPS} alt="" />
              </div>
              <div className="TextOops">
         <div className="TextDiv">
         <h2>OOPS! PAGE NOT FOUND</h2>   
         <h6>The requested page dose not exist.</h6>      
         </div>
         <Link to={`/home`}>         <input type='submit' className='returnButton' value='Return to Home Page'/>
</Link>
         </div>
             
             
              
            </div>
            
        </div>
      </section>
          </>
        );
      };
