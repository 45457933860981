import { Link, useLocation,useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';

import React, { useState, useEffect} from 'react';

import './Showcontact.scss';
import {BiTrash} from 'react-icons/bi'
import {BiEdit} from 'react-icons/bi'
import axios from "axios"


function truncateText(text, maxLength) {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxLength);
      return truncatedWords.join(' ') + '...';
    }
  }
const ShowCotact= ()=>{
 




  const [data, setData] = useState([]);

  const [currenItems, setCurrentItems]= useState([])
  
  //const articleid = location.pathname.split("/")[3] ;
  
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        //const res = await axios.get(`/other/`)
        const res = await axios.get(`https://api.bethought.org/api/other/`)
        
        setData(res.data); 
                setCurrentItems(res.data.slice(0, itemsPerPage));
    
      }catch(err){
        console.log(err)
  
      }
    }
    fetchData();
   },[])

   
   const { contactid } = useParams();

  const handleDelete = async (id)=>{
    if (window.confirm('Are you sure you want to delete this Message?')) {

    try {
      console.log('Deleting article with ID:',id);

      //await axios.delete(`/other/${id}`);
      await axios.delete(`https://api.bethought.org/api/other/${id}`);

      console.log('message deleted successfully');
      // Handle successful deletion or update your articles list
    } catch (err) {
      console.error('Error deleting massage:', err);
      // Handle errors
    }

  }

  }

  const getText =(html) =>{
    const doc = new DOMParser().parseFromString(html,"text/html")
    return  doc.body.textContent
  }
 
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage= 3;


    useEffect(()=> {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    },[itemOffset,itemsPerPage,data]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % data.length;
  
  setItemOffset(newOffset);
};
  return(
    
        <> 
          <div className="newContact">
            <div className="contact">
          <div className="topDiv flex"> 
            <div className="titleText ">
                    <span className='title'> Contact</span>

                </div>
             </div> 

               <div className='ShowContactContainer'>
               <div class="row flex-grow">
    <div class=" grid-margin stretch-card">
      <div class="card  shadow" >
        <div class="card-body">
               <div class="table-responsive  mt-1">
                                  <table class="table select-table">
                                    <thead>
                                      <tr>
                                       
                                        <th> Full Name</th>
                                        <th>Email</th>
                                        <th>Message</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {currenItems.map((contact) =>(
                                       <>
                                    <tr>
                                     
                                     <td>
                                       <div class="d-flex">
                                         <img src="img/images (3).jpg" alt=""/>
                                         <div>
                                           <h6>{contact.fullName}</h6>
                                         
                                         </div>
                                       </div>
                                     </td>
                                     <td>
                                     <h6>{contact.email}</h6>
                                      
                                     </td>
                                     <td>
                                     
                                         
                                           <p>{contact.msg}</p>
                                         
                                     </td>
                                     <td>
                                     <BiTrash className='icontrash'  onClick={()=>handleDelete(contact.id)} />
                                 </td>
                                   </tr>
                                   </>
           
                                   ))}
                                    </tbody>
                                  </table>
          
               </div> </div></div></div></div></div>
               <ReactPaginate
        breakLabel=""
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageLinkClassName="page-num"
        previousLinkClassName='page-num prev'
        pageRangeDisplayedClassName=''
        nextLinkClassName='page-num next'
        activeLinkClassName='active'
      />
            </div>

            
        </div>
        
    </>
        )
}

export default ShowCotact;
