import React from 'react';
import {Link, useNavigate} from "react-router-dom";

import '../../dash.scss';
import './sidebar.scss';

import logo from '../../../../assets/logo-black.svg'
import {MdOutlineArticle} from 'react-icons/md';
import {BiHomeAlt2}  from 'react-icons/bi'
import {PiArticleMedium} from 'react-icons/pi';
import {FiLogOut}  from 'react-icons/fi';
import {RiContactsLine} from 'react-icons/ri'
import { RiUserAddLine } from "react-icons/ri";

import { useContext } from 'react';
import { AuthContext } from '../../../../context/authContext';
const Sidebar = ()=>{
    const {currentUser,logout}= useContext(AuthContext);
    return(
    
        <>  
         {currentUser?
        <div className='sideBar'>
            <div className="logoDiv flex">
                <img src={logo} alt="" />
            </div>
               <div className="menu">
                <ul className="navItem">
                          
                
                <Link className='navList' to="/dashboard/showarticle" >
                <BiHomeAlt2 className='icon'/>
                    </Link>
                    
                    <Link className="navList" to="/dashboard/addnewarticle">
                        <PiArticleMedium className='icon'/>
                    </Link>
                    <Link className="navList" to="/dashboard/ShowContact">
                        <RiContactsLine className='icon'/>
                    </Link>
                    <Link className="navList" to="/dashboard/ShowSubscribres">
                        <RiUserAddLine className='icon'/>
                    </Link>
                   
                    <Link className="navList" to="/login" >
                      <FiLogOut className='icon'  onClick={logout}/> 
                    </Link> 
                   
                </ul>
               </div>
            
            </div>
            : ""} </>
        )
}

export default Sidebar ;
