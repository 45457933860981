import { Link, useLocation,useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';

import React, { useState, useEffect} from 'react';

import './Showsub.scss';
import {BiTrash} from 'react-icons/bi'
import {BiEdit} from 'react-icons/bi'
import { RiUserAddLine } from "react-icons/ri";

import axios from "axios"


function truncateText(text, maxLength) {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxLength);
      return truncatedWords.join(' ') + '...';
    }
  }
const ShowSub= ()=>{
 




  const [data, setData] = useState([]);

  const [currenItems, setCurrentItems]= useState([])
      const [subscriberCount, setSubscriberCount] = useState(0);

  
  //const articleid = location.pathname.split("/")[3] ;
  /*
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        //const res = await axios.get(`/other/`)
        const res = await axios.get(`https://bethought.onrender.com/api/other/subscribers`)
        
        setData(res.data); 
                setCurrentItems(res.data.slice(0, itemsPerPage));
    
      }catch(err){
        console.log(err)
  
      }
    }
    fetchData();
   },[])
*/
     useEffect(() => {
     const fetchData = async () => {
      try {
        const [subscribersRes, countRes] = await Promise.all([
          axios.get('https://bethought-45143776de10.herokuapp.com/api/other/subscribers'),
          axios.get('https://bethought-45143776de10.herokuapp.com/api/other/countsub'),
        ]);

        setData(subscribersRes.data);
        setCurrentItems(subscribersRes.data.slice(0, itemsPerPage));
        setSubscriberCount(countRes.data[0]['COUNT(*)']);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

   
   const { contactid } = useParams();

  const handleDelete = async (id)=>{
    if (window.confirm('Are you sure you want to delete this subscriber?')) {

    try {
      console.log('Deleting subscriber with ID:',id);

      //await axios.delete(`/other/${id}`);
      await axios.delete(`https://api.bethought.org/api/other/subscriber/${id}`);

      console.log('subscriber deleted successfully');
      // Handle successful deletion or update your articles list
    } catch (err) {
      console.error('Error deleting subscriber:', err);
      // Handle errors
    }

  }

  }

  const getText =(html) =>{
    const doc = new DOMParser().parseFromString(html,"text/html")
    return  doc.body.textContent
  }
 
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage= 10;


    useEffect(()=> {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    },[itemOffset,itemsPerPage,data]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % data.length;
  
  setItemOffset(newOffset);
};
  return(
    
        <> 
          <div className="newSub">
            <div className="Subscriber">
          <div className="topDiv flex"> 
            <div className="titleText ">
                    <span className='title'> Subscribers</span>

                </div>
                <div className=" ">
                
                      <span>Total Subscribers: {subscriberCount}</span>
                    
                </div>
             </div> 

               <div className='ShowSubContainer'>
               <div class="row flex-grow">
    <div class=" grid-margin stretch-card">
      <div class="card  shadow" >
        <div class="card-body">
               <div class="table-responsive  mt-1">
                                  <table class="table select-table">
                                    <thead>
                                      <tr>
                                       
                                     
                                        <th>Email</th>
                                      
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {currenItems.map((subcsriber) =>(
                                       <>
                                    <tr>
                                     
                                   
                                     <td>
                                     <h6>{subcsriber.email}</h6>
                                      
                                     </td>
                                    
                                     <td>
                                     <BiTrash className='icontrash'  onClick={()=>handleDelete(subcsriber.id)} />
                                 </td>
                                   </tr>
                                   </>
           
                                   ))}
                                    </tbody>
                                  </table>
          
               </div> </div></div></div></div></div>
               <ReactPaginate
        breakLabel=""
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageLinkClassName="page-num"
        previousLinkClassName='page-num prev'
        pageRangeDisplayedClassName=''
        nextLinkClassName='page-num next'
        activeLinkClassName='active'
      />
            </div>

            
        </div>
        
    </>
        )
}

export default ShowSub;
