import React ,{useState}  from 'react';
import './Home.scss';
import { Link } from 'react-router-dom'; // Import useHistory from React Router

function HomeSearch (){
    
    const [searchTerm, setSearchTerm] = useState(''); // State to track the search term

    // Function to handle the search input change
    const handleSearchInputChange = (event) => {
      setSearchTerm(event.target.value);
    };
    return(

<div className="HomeSearch">
    <div className="searchDiv">
        <input type="text" className='searchInput' 
          placeholder="Search..."    onChange={handleSearchInputChange}
        />
        
    </div>
    <Link to={`/articles?search=${searchTerm}`}> <input type='submit' className='searchButton' value='Search'/> </Link>
      
</div>
        )
}

export default HomeSearch;