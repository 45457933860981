import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import moment from 'moment';




class UploadAdapter {
  constructor(loader) {
      this.loader = loader;
      this.imgUrl = null;
  }

  upload() {
      return this.loader.file
          .then(file => new Promise((resolve, reject) => {
            
              const formData = new FormData();
              formData.append('image', file);

              // Replace with your server endpoint
              fetch('https://api.bethought.org/api/uploads', {
                  method: 'POST',
                  body: formData
              })
              .then(response => response.json())
              .then(data => {
                  if (data && data.imgUrl) {
                   // this.loader.fileLoader.uploaded = true;
                      resolve({ default: data.imgUrl });
                  } else {
                      reject('Upload failed');
                  }
              })
              .catch(error => {
                  reject('Upload failed: ' + error);
              });
          }));
  }

  abort() {
      // Implement this if needed for cancellation
  }
}

class  MyCkeditor extends Component {
  

  constructor(props) {
    super(props);
    const articleId = props.state ? props.state.id : null;
    this.state = {
      articleId: articleId,
      editorData: props.state?.desc|| '<p>Create Your Articles Here!</p>',
      title:props.state?.title|| '',
      imgValue: '', // Add your imgValue state here
      dateValue: moment().format("YYYY-MM-DD"), // Add your dateValue state here
      catValue: props.state?.cat||'',
    };
  }

  handleEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ editorData: data });
   console.log({ event, editor, data });
  };
  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  
  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
  
    fetch('https://api.bethought.org/api/upload/', { 
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const { imgUrl } = data;
      //  console.log(`Uploaded file: ${imgUrl}`);
        
        // Update the state with the filename
        this.setState({ imgValue: imgUrl });
    })
      .catch((error) => {
        console.error(error);
      });
  };
  handleCatChange = (event) => {
    this.setState({ catValue: event.target.value });
  };
  handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      //const response = await fetch('http://localhost:3001/api/uploads/', {
        const response = await fetch('https://api.bethought.org/api/uploads/', {

        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.imgUrl) {
          const editor = this.editorInstance;
          editor.model.change((writer) => {
            const imageElement = writer.createElement('image', {
              src: data.imgUrl,
            });
            editor.model.insertContent(imageElement);
          });
        }else {
          console.error('Image upload error: Invalid data or missing URL');
        }
      } else {
        console.error('Image upload error: Server returned a non-OK status');
      
      }
    } catch (error) {
      console.error('Image upload error:', error);
    }
  };
 
  handleSave = (event) => {
    event.preventDefault();
    const {articleId, title, editorData , imgValue, dateValue,  catValue } = this.state;
   
    if (articleId) {
      // Update existing article
      //fetch(`http://localhost:3001/api/article/${articleId}`, {
        fetch(`https://api.bethought.org/api/article/${articleId}`, {

        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: title,
          desc: editorData,
          img: imgValue,
        
          cat: catValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data.message);
                  					 alert("article has been updated!");

        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    fetch(`https://api.bethought.org/api/article/save/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify({ 
        title: title, 
         content: editorData,
         imgValue: imgValue, // Add your imgValue here
         dateValue: dateValue, // Add your dateValue here
         catValue: catValue,
         }),
    })
      .then((response) => response.json())
      .then((data) => {
        					 alert(data.message);

        //console.log(data.message); 
       
        
      })
      .catch((error) => {
        console.error(error);
      });
  }};
  
  
  

    render() {
      const { title , catValue } = this.state;

        return (
          <div className="newArticle row" >
          <div className="content col-sm-12">
              <div className="" >
                             <input   value={title}
        type="text"
        placeholder="Title"
        onChange={this.handleTitleChange}  />  
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.editorData}
                    onReady={(editor) => {
                      this.editorInstance = editor;
                     console.log( 'Editor is ready to use!', editor );
                      
                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                            return new UploadAdapter(loader);
                             // Handle the image upload asynchronously
                             
            
                        };
                    }}
                    onChange={this.handleEditorChange}
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            
            </div>
            </div>
 
            <div className="menu col-sm-12">
                <div className="item1">
                <h1> {this.state.articleId ? 'Update' : 'Publish'}</h1>
                
                <input style={{display:"none"}} type="file" id='file' name="" onChange={this.handleFileChange}  />
                <label className='file' htmlFor='file'>Upload Cover Article</label>
               
                <div className="buttons">
                    <button onClick={this.handleSave} id='publishButton'>{this.state.articleId ? 'Update' : 'Publish'}</button>
                </div>


                </div>
                <div className="item">
                    <h1>Category</h1>
                    <div className="cat">
                        <input type="radio" name='catValue' value="Arabic literature" id="" checked={catValue === "Arabic literature"}  onChange={this.handleCatChange}/>
                    <label htmlFor="Arabic literature">Arabic literature</label>
                    </div>
                    <div className="cat">
                    <input type="radio" name='cat'  value="Postcolonial literature" id=""  checked={catValue === "Postcolonial literature"}  onChange={this.handleCatChange}/>
                    <label htmlFor="Postcolonial literature">Postcolonial literature</label>
                    </div>
                    <div className="cat">
                    <input type="radio"  name='cat' value="Children Literature" id="travel"  checked={catValue === "Children Literature"}  onChange={this.handleCatChange}/>
                    <label htmlFor="Children Literature">Children Literature</label>
                    </div>
                     <div className="cat">
                    <input type="radio"  name='cat' value="western" id="travel"  checked={catValue === "western"}  onChange={this.handleCatChange}/>
                    <label htmlFor="western">western</label>
                    </div>
                    <div className="cat">
                    <input type="radio"  name='cat' value="autobiographies" id="travel"  checked={catValue === "autobiographies"}  onChange={this.handleCatChange}/>
                    <label htmlFor="autobiographies">autobiographies</label>
                    </div>
                </div>

            </div>
           
        </div>
        );
    }
}

export default MyCkeditor;

