import React , { useState } from 'react'

import logo from '../../assets/logo.png';
import axios from "axios"
const Forgot = ()=>{

  const [inputs,setInputs]=useState({
   
    email:"",
  
  })
    const [loading, setLoading] = useState(false);

    const handelChange = e =>{
      setInputs(prev =>({...prev,[e.target.name]:e.target.value}))
    
    }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: inputs.email,
    };
    
      try {
        setLoading(true);

        // Make the POST request using Axios
        const response = await axios.post('/auth/forgotPassword', data);
      //  console.log('res',response)
    
        if (response.status === 200) {
          // Request was successful, show a success message or redirect the user.
        //  console.log('Password reset email sent successfully');
	alert('Password reset email sent successfully!');

        } 
        if (response.data && response.data.message === 'Email does not exist') {
              console.log('Email does not exist');
              alert('Email does not exist!');
            }
        
      } catch (error) {
        // Handle network errors or other exceptions here
       console.error('An error occurred:', error);
	            alert("Email does not exist !!");

      } finally {
        setLoading(false);
      }
  };
     
    return(
        <>
         <div className="auth ftco-section"  >
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section">
            <img src={logo} alt=""/>
          </h2>
				</div>
			</div>
			<div className="row justify-content-center">
        
				<div className="col-md-6 col-lg-5">
        <h3 className="text-center mb-4 text-white">Forgot Password</h3>

					<div className="login-wrap p-4 p-md-5">
		      	<div className="icon d-flex align-items-center justify-content-center">
		      	</div>
						<form  className="login-form" method="post" >
		      		<div className="form-group">
		      			<input type="email"  className="form-control rounded-left" placeholder=" Your Email" name="email"  required onChange={handelChange} />
		      		</div>
              {loading && <div className="text-center">Loading...</div>}
	            <div className="form-group">
	            	<button type="submit" name="submit" className="btn btn-primary rounded submit p-3 px-5" onClick={handleSubmit} >Submit </button>
	            </div>
	          </form>
	        </div>
				</div>
			</div>
		</div>
	</div>
        </>
    )
}

export default Forgot;
