
import { Link} from "react-router-dom";
import React, { useState, useEffect} from 'react';
import axios from "axios"
import './Article.scss';
import Subsecribe from '../../../../components/subsecribe/Subsecribe';
import Navbar from '../../../../components/Navbar';

function truncateText(text, maxLength) {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxLength);
      return truncatedWords.join(' ') + '...';
    }
  }


function Articles (){
  const [search, setSearchTerm] = useState('');
  //const [articles, setArticles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

    const [articles, setArticles]= useState([])
   
    useEffect(()=>{
        const fetchData = async()=>{
          try{
            //const res = await axios.get(`/article`)
            const res = await axios.get(`https://api.bethought.org/api/article`)

            setArticles(res.data); // Initially display first 'itemsPerPage' items
            console.log('API Response:', res.data); // Log the response data

        
          }catch(err){
            console.log(err)
      
          }
        }
        fetchData();
       },[])
    
    useEffect(() => {
        document.title = 'Articles'; 
      }, []);
      
  useEffect(() => {
    // Filter articles based on search term
    if (search) {
      const filteredResults = articles.filter((article) =>
        article.title.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [search, articles]);
    
    const isArPage = true;
    const getText =(html) =>{
      const doc = new DOMParser().parseFromString(html,"text/html")
      return  doc.body.textContent
    }
    return(
<>
<Navbar isArPage={isArPage}/>
<div class="ArticlesContainer">
    <div class="input__group">
        <input autoComplete="off"
          type="text"
          placeholder="Search…"
          className="chakra-input css-vdreux"
          value={search}
          onChange={(e) => setSearchTerm(e.target.value)}/>
        </div>
       {/* Render search results */}
       {search && searchResults.length === 0 && (
        <p>No matching articles found.</p>
      )}

      {search && searchResults.length > 0 && (
        <div className="search-results">
          <ul>
            {searchResults.map((article) => (
               <Link to={`/article/${article.id}/${article.title}`} key={article.id}>
               <li class="ar-li">
                   <h2 class="ar-heading ">{article.title}</h2>
                   <p class="ar-text ">
                   {getText(truncateText(article.desc, 20))}                   </p>
                  <span >{new Date(article.date).toLocaleDateString('en-US')}</span>
               </li>
           </Link>
            ))}
          </ul>
        </div>
      )}
        {!search && (
        <div className="all-articles">
          <ul role="list">
            {Array.isArray(articles) && articles.map((article) => (
              
            <Link to={`/article/${article.id}/${article.title}`} key={article.id}>
            <li class="ar-li">
                <h2 class="ar-heading ">{article.title}</h2>
                <p class="ar-text ">
                {getText(truncateText(article.desc, 20))}                   </p>
               <span >{new Date(article.date).toLocaleDateString('en-US')}</span>
            </li>
        </Link>
            ))}
          </ul>
        </div>
      )}
            {/***  <ul role="list" class="">
       
      
            {articles.map(card=>{
            return(
              <>
        
            <Link to={`/article/${card.id}`} key={card.id}>
                <li class="ar-li">
                    <h2 class="ar-heading ">{card.title}</h2>
                    <p class="ar-text ">
                    {getText(truncateText(card.desc, 20))}                   </p>
                   <span >June 20, 2023</span>
                </li>
            </Link>
            </>
            );
            })}
            
            </ul> */}
            </div>
 <Subsecribe/>
 </>

 )
 }
 
 export default Articles;
