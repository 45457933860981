import React from "react";
import "./scrollUp.css";
import scrollup from '../../assets/scrollup.png'
const ScrollUp = () =>{
    window.addEventListener("scroll",function(){
        const scrollUp = document.querySelector(".scrollup");
        if (this.scrollY >=560)scrollUp.classList.add("show-scroll");
        else scrollUp.classList.remove("show-scroll");  
    })
    return(
        <a href="#" className="scrollup">
            <img className="uil uil-arrow-up scrollup__icon" src={scrollup}/>
        </a>
    )
}

export default ScrollUp;