
import React , {useEffect,useState}  from 'react';
import { Link , useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from "axios"

import view from '../../../../assets/carbon_view.png'
import arrow from '../../../../assets/arrow.svg'
import './Card.scss'

import './Article.scss';
import Lastarticle from './Lastarticle';

function truncateText(text, maxLength) {
  const words = text.split(' ');
  if (words.length <= maxLength) {
    return text;
  } else {
    const truncatedWords = words.slice(0, maxLength);
    return truncatedWords.join(' ') + '...';
  }
}

const Article = ()=>{

  const [clickCount, setClickCount] = useState(0);
  

   const URL=process.env.BASE_URL;

  const [data, setData] = useState([]);
  const [currenItems, setCurrentItems]= useState([])
  const [viewCounts, setViewCounts] = useState([]); // Initialize view counts state

  const incrementViewCount = () => {
    setClickCount(clickCount + 1);
  };
  
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        //const res = await axios.get(`/article/`)
        const res = await axios.get(`https://api.bethought.org/api/article/`)
       
        setData(res.data); 
      
        setCurrentItems(res.data.slice(0, itemsPerPage)); 
      setClickCount(res.data[0].nbrView);
    
    
      }catch(err){
       // console.log(err)
  
      }
    }
    fetchData();
   },[])
 
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage= 3;


    useEffect(()=> {
      const endOffset = itemOffset + itemsPerPage;
      if (Array.isArray(data)) {
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
      //setCurrentItems(data.slice(itemOffset, endOffset));
      //setPageCount(Math.ceil(data.length / itemsPerPage));
    },[itemOffset,itemsPerPage,data]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % data.length;
  
  setItemOffset(newOffset);
};

const getText =(html) =>{
  const doc = new DOMParser().parseFromString(html,"text/html")
  return  doc.body.textContent
}

const updateClickCount = async (id) => {
  try {
    const response = await axios.put(`https://bethought-45143776de10.herokuapp.com/api/article/updateClickCount/${id}`, {
      nbrView: 1, // Set the newClickCount value to 1, or any other value you want to increment by
    });
    // Handle the response if needed
    if (response.status === 200) {
      
      setClickCount(clickCount + 1); // Update the local click count
    }
  } catch (error) {
    // Handle errors
    console.log("no updating count")
    console.log("Error updating count", error);

  }
};
    return(
<>

<div className='lounge container '>
 <Lastarticle/>
<div className="cards">
<div className="container ">
      <div className="pagicard">
    
      <div className="row">
        {currenItems.map((card, index) =>{
           return(
              <>
             <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={card.id}>
             <div className="card  h-100 card-inverse card-primary " >
               <img className="card-img-top" src={card.img}  alt={card.title}/>
               <div className="card-body pl-0 ml-0">
               <h3 className="card-title  ">{card.title}</h3>
                   <p>
                {getText( truncateText(card.desc, 20))}  
                    </p>
                   <div className='readArt'>
                   <p className="css-1p852hr">Posted on<span>  {new Date(card.date).toLocaleDateString('en-US')} </span></p>
                   <div className="view"> {card.nbrView}<img className="carbon-view" alt="Carbon view" src={view} /></div>

                
                   <Link
  to={`/article/${card.id}/${encodeURIComponent(card.title)}`} 
  key={card.id}
  onClick={() => {
    updateClickCount(card.id)
    incrementViewCount();
  }}

>
                      
                       Read Article <img src={arrow} alt="" /></Link> 
                   </div>
                   </div>
           </div>
           </div>
           </>
            );
        })}
    </div>

    <ReactPaginate
        breakLabel=""
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageLinkClassName="page-num"
        previousLinkClassName='page-num prev'
        pageRangeDisplayedClassName=''
        nextLinkClassName='page-num next'
        activeLinkClassName='active'
      />
 
   

</div>
        </div>
</div>

 </div>

 </>

 )
 }
 
 export default Article;
