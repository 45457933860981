import React , { useEffect } from 'react'; 


import './Home.scss';

import HomeSearch from './HomeSearch';
import kodama from '../../../assets/kodama.png'
import Article from './article/Article';
import Subsecribe from '../../../components/subsecribe/Subsecribe';
const Home = ()=>{
    
    useEffect(() => {
        document.title = 'Bethought - Home Page'; 
      }, []);
    
    return(

<>


<section className="home " id="home">
        <div className='effectImg'></div>

        <div className="home__container container grid">
            <div className="home__content grid">
            
            <div className="home__data">
            <h1 className="home__title">
          
            Bethought; Making every reflective observation count.                
                </h1>
            <p className="home__description">
                    <span>"I just want to write as trustfully as I can without trying to say something noble."
                         </span>
                         <br />
                     <span>- Abdulrazak Gurnah</span>

                </p>
                
        </div>
        <HomeSearch />
             
             
              
            </div>
            <div className="home__img">
                <img src={kodama} alt="" />
              </div>
        </div>
      </section>
      <Article/>
      <Subsecribe/>
      
      
 </>
)
}

export default Home;
