import React , {useEffect,useState}  from 'react';


import './About.scss'

import carousal1 from '../../../assets/bochrabethoughtowner1.jpg'
import carousal2 from '../../../assets/bochrabethoughtowner2.jpg'

import carousal3 from '../../../assets/bochrabethoughtowner3.jpg'

import carousal4 from '../../../assets/bochrabethoughtowner4.jpg'
import flag from '../../../assets/Flag.svg'

import green from '../../../assets/green.png'
import Navbar from '../../../components/Navbar';
import { Aboutme } from './Aboutme';

    export const About = () => {
      useEffect(() => {
        document.title = 'Beyond'; 
      }, []);
      const [carouselItems, setCarouselItems] = useState([
        carousal2,
        carousal1,
      carousal3,
       
      ]);
      const [activeImage, setActiveImage] = useState(carousal4);
    
      const onImgClick = (item) => {
       
        if (activeImage === null) {
          setActiveImage(item);
        }else {
        setCarouselItems(prevItems => {
          const newItems = prevItems.map(prevItem => prevItem === item ? activeImage : prevItem);
          setActiveImage(item);
          return newItems;
        });
      }}

        return (
          <>
         
          <Aboutme/>
         
         <section className='section-about '>
          <div className="row hh">
           <div className="about-slider col-xl col-sm-12 col-md-6">
            
              <div className="overlap  ">
                <div className="group">
                  <div className="overlap-group ">
                    <div className="rectangle" />
                    <img className="transparency" alt="Transparency" src={green} />
                    <img className="img" alt="Rectangle" src={activeImage} />
                  </div>
                </div>
                <div className='test'>
                {carouselItems.map((item) => (
                <div   className={`div ${activeImage === item ? 'active' : ''}`} 
                key={item}
                onClick={() => {
                  onImgClick(item);
                }}>
                  <img className="rectangle-2" alt="Rectangle" src={item} />
                    </div>
                   ))}</div>
              </div>
             
              </div>
            <div className="div-2 col-xl-6 col-sm-12 col-md-6">
              
              <p className="what-is-the-purpose ">What Is  <span>The Purpose Of My Blog?</span> </p>
              <div className="hello-i-bet-you">
                <span>Hello there ,</span> <br/><br/>
              <p>
Probably, 
                  you have noticed the dark green woods and sensed the creepy vibe the Kodama creature gave on the home page. 
                  And you may question what the purpose of this blog is. Beyond Self-expression, discussing literature topics, 
                  and owing gratitude and admiration to my favorite dead and alive authors’ treasurable works, 
                  I want to write as trustfully as I can. 

                </p>  
                <br/><br/>
                <span> Would you like to join me on my next treasure-hunting trip?

</span>
              </div> 
            </div>
            </div>
        
         
          </section>
             
          </>
        );
      };
