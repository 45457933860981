import React , {useState, useEffect } from 'react'; 
import axios from "axios"

import './contact.scss';
import email from '../../../assets/email.png';
import contact from '../../../assets/contact.svg';
import pin from '../../../assets/pinterest_icon.png'
import insta from '../../../assets/insta1.png'
import face from '../../../assets/face.png'
import apiUrl from '../../../apiConfing';

import reddit from '../../../assets/reddit.png'
import twitter from '../../../assets/twitter1.svg'
import Subsecribe from '../../../components/subsecribe/Subsecribe';
const  Contact = ()=>{

  useEffect(() => {
    document.title = 'Contact Us'; 
  }, []);
  const isCoPage = true;
  const [inputs,setInputs]=useState({
    fullName:"",
    email:"",
    msg:"",
  })
  const [err,setError] = useState(null)
  
  
  const handelChange = e =>{
    setInputs(prev =>({...prev,[e.target.name]:e.target.value}))
  
  }
  const handelSubmit= async e=>{
    e.preventDefault()
    console.log("Submit button clicked");
       try{
      await axios.post(`/other/`, inputs)
    }catch(err)
       {
             //console.log(err)
         setError(err.response.data)
            }

  }

    return(
        <>
         <section className='firstDiv '>
                    <div className='effectImg'></div>

           <div className="divtext container ">
<div className="div ">
<div className="text-wrapper">Let's Make The First Step And Get in Touch.
</div>

</div>


</div>
<div className='contact'>
  <div className="contactCard row">
  <div className="col-6 contact-info">
      <p className=''>Contact </p>
      <p className="">
   
      Wanting To Give A Feedback? <br />
      Having Something To Say To The Author? Don't Hesitate To Contact Me!
</p>

      <a className="mail"><img className="img" alt="icons email" src={email} />
      <span> bethought.blog@gmail.com</span>
      </a>
      <div className="div">
      <a target='_blank' href="https://www.facebook.com/Bethought.blog"><img className="img" alt="icons facebook" src={face} /></a> 
      <a target='_blank' href="https://twitter.com/Bethoughtblog"><img className="img" alt="icons twitter" src={twitter} /></a> 

   <a target='_blank' href="https://www.pinterest.com/bethoughtblog/"><img className="img" alt="icons pinterest" src={pin} /></a> 
   <a target='_blank' href="https://www.instagram.com/bethought.blog/"><img className="img" alt=" instagram" src={insta} /></a> 
   <a target='_blank' href="https://www.reddit.com/user/Bethought/"><img className="img" alt="" src={reddit} /></a> 

</div>

     </div>
     <div className="col-6">
   
<form>
  <div className="form-row row">
    <div className="form-group col">
      <label >Full Name</label>
      <input type="text" className="form-control  "  placeholder="Your full name" name='fullName' onChange={handelChange} required/>
    </div>
    
  </div>
  <div className="form-group ">
    <label >Email</label>
    <input type="email" className="form-control  " id="" placeholder="Your mail"  name='email' onChange={handelChange}/>
  </div>
  <div className="form-group " >
    <label >Message</label>
    <textarea className="form-control "placeholder="Your message here.."rows="3" name='msg' required onChange={handelChange}></textarea>
  </div>
  
  <button type="submit" className="btn  " onClick={handelSubmit}>Send Message</button>

</form>
</div>
    
 

</div>
</div>
</section>

<Subsecribe isCoPage={isCoPage}/>

                                       
</>)
    
}
export default Contact;
