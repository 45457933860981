import {useLocation, useParams} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../../assets/hero-bg.png';

import RecomArticle from "./RecomArticle";
import Share from "./Share/Share"

import './Article.scss';
import view from '../../../../assets/carbon_view.png'

import Navbar from '../../../../components/Navbar';
import BuyCoffee from '../../../../components/buyCoffee/BuyCoffee';
import DOMPurify from 'dompurify';
import axios from "axios"
import './Card.scss'

function SingleArticle (){

  const { id ,title} = useParams();
  let currentUrl = window.location.href; 
 
  const [data, setData] = useState([]);
  const [article, setArticles]= useState({
  title: '',
    desc: '',
    img: '',
  })
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        //const res = await axios.get(`/article/${id}`)
       // const res = await axios.get(`https://bethought.onrender.com/api/article/${id}/${title}`)
        //console.log('API Response:', res.data); // Log the response data
        const res = await axios.get(`https://api.bethought.org/api/article/${id}/${title}`)

        setData(res.data); // Store the fetched data in the 'data' state
        setArticles(res.data); // Initially display first 'itemsPerPage' items
      console.log('Title:', res.data.title);
      console.log('Description:', res.data.desc);
      console.log('Image URL:', res.data.img);
        console.log('API Response:', res.data);


    
      }catch(err){
       // console.log(err)
        //console.error('Error fetching data:', err);
      }
    }
    fetchData();
   },[id])
 




    const isArPage = true;
   


 
  function replaceOembedWithIframe(content) {

    if (!content) return content;
  
    // Replace <oembed> tags with <iframe> tags for YouTube videos
    const replacedContent = content.replace(/<oembed url="([^"]+)"><\/oembed>/g, (match, url) => {
   

      if (url.includes('youtube.com')) {
        const videoId = url.split('embed/')[1];
        return ` 
        <div className='ratio ratio-16x9'><iframe width="100%" height="315" src="https://www.youtube.com/embed/${videoId}" title="YouTube Video" allow="autoplay" frameBorder="0" allowFullScreen></iframe></div>`;
      }

      return match; // Return the original tag for non-YouTube content
    });

  
    // Set width: 100% for all images
    const contentWithWidthSet = replacedContent.replace(/<img/g, '<img style="width:100%;height: 100%;"');
  
    const contentWithStyles = contentWithWidthSet.replace(/<a href="https:\/\/www\.youtube\.com[^"]+">/g, (match) => {
      return match.replace('<a', '<a class="centered-link"');
    });
    
    const imageUrls = contentWithStyles.match(/<img src="([^"]+)"[^>]+>/g);

    if (!imageUrls || imageUrls.length === 0) {
      // No images found, return the original content
      return contentWithStyles;
    }
  
    // Create a Bootstrap carousel with the extracted image URLs
    const carouselHtml = `
      <div id="article-carousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          ${imageUrls.map((imageUrl, index) => `
            <div class="carousel-item${index === 0 ? ' active' : ''}">
              ${imageUrl}
            </div>
          `).join('')}
        </div>
        <a class="carousel-control-prev" href="#article-carousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#article-carousel" role a button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    `;
  
    // Replace the <figure class="media"><oembed> tags with the Bootstrap carousel
    const contentWithCarousel = contentWithStyles.replace(/<figure class="image"><img src="([^"]+)\/><\/figure>/g, carouselHtml);

    return contentWithCarousel;
  }
    const imageUrl = `${window.location.origin}${logo}`; 
    return(
<>
  
  <Helmet>
    <meta charset="utf-8" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content={currentUrl} />
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={article.title} />
     <meta property="quote" content={article.title} />
     <meta name="description" content={article.desc} />
     <meta property="image" content={article.img} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={article.title} />
     <meta property="og:quote" content={article.title} />
     <meta property="og:image" content={article.img} />
     <meta content="image/*" property="og:image:type" />
    
<meta property="og:image:width" content="600" />
<meta property="og:image:height" content="314" />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="Bethougth" />
     <meta property="og:description" content={article.desc} />   
         <title>{article.title}</title>


</Helmet>


<Navbar isArPage={isArPage}/>
   <div className="bigcontainer ">
        <div className="frame "  >
            <div className="group">
  
                <p className="text-wrapper">
{article.title}                </p>
                <div className="text-wrapper-11">{article?.cat} | Posted on {new Date(article.date).toLocaleDateString('en-US')}
                <div className="view">{article.nbrView} <img className="carbon-view" alt="Carbon view" src={view} /></div>
                   </div>
                <p className="p" >
                <div dangerouslySetInnerHTML={{ __html: replaceOembedWithIframe(article.desc) }} />
                  {/*getText(article.desc)*/}
                  
                </p>

            </div>

        </div>
                   <Share  article={article}/>

<RecomArticle cat={article.cat}/>
        
        </div>
       
        <div className="">


                </div>
<BuyCoffee  />
</>


 )
 }
 
export default SingleArticle; 
