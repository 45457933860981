
import view from '../../../../assets/carbon_view.png'
import arrow from '../../../../assets/arrow.svg'
import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";

import axios from "axios"
import './Card.scss'
import './Article.scss'


function truncateText(text, maxLength) {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxLength);
      return truncatedWords.join(' ') + '...';
    }
  }
const RecomArticle = ({cat})=>{
    const [article, setArticles]= useState([])
 
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const res = await axios.get(`https://api.bethought.org/api/article/?cat=${cat}`)

        //const res = await axios.get(`/article/?cat=${cat}`)
        console.log('API Response:', res.data); // Log the response data
         
        setArticles(res.data); // Initially display first 'itemsPerPage' items
    
    
      }catch(err){
        console.log(err)
        console.error('Error fetching data:', err);
      }
    }
    fetchData();
   },[cat])
   const getText =(html) =>{
    const doc = new DOMParser().parseFromString(html,"text/html")
    return  doc.body.textContent
  }
 return(
    <>
    <div  className="section2 row" id="stop">
          <p className="title" >  You may also like</p>
              {article.map(card=>{
                return(
  <div className="col-md-6 col-sm-12 mb-4" key={card.id}>
  <div className="card  h-100 card-inverse card-primary " >

    <img className="card-img-top" src={card.img} alt={card.title} />
    <div className="card-body pl-0 ml-0">
    <h3 className="card-title  ">
  {card.title}</h3>
        <p>
        {getText( truncateText(card.desc, 20))}  
           </p>
        <div className='readArt'>
        <p className="css-1p852hr">Posted on<span>  {new Date(card.date).toLocaleDateString('en-US')}  </span></p>
        <div className="view">{card.nbrView}  <img className="carbon-view" alt="Carbon view" src={view} /></div>

        <Link to={`/article/${card.id}`} key={card.id}>Read Article <img src={arrow} alt="" /></Link> 
        </div>
        </div>
</div>
</div>)
})}
        
                </div></>



)
}

export default RecomArticle;
