
import React , {useState, useEffect, useRef  }  from 'react';
import {Link} from "react-router-dom";
import Coffee from '../../assets/coffe.svg'
import './buycoffee.scss';


const  BuyCoffee = ()=>{

    return(
        <>



<div  className='buy' id="buy">
                  
                  <div className="buyContent">
                  <div className="label">

                  
<p className="text-wrapper"> If you enjoy and benefit from the content I provide,</p>
<p className="text-wrapper">You can support Bethought Blog through the following platform.</p>


</div>
<div className="subSearch">
  
  <a type='submit' className='buyButton' href='https://www.buymeacoffee.com/bethoughtblog'> 
  <img src={Coffee} alt="" />
  buy me a Coffee
              </a>
</div>
</div>
                      
              </div>


              </>
    )
    
}

export default BuyCoffee;              