
import React, { useContext }  from 'react'
import logo from '../../assets/logo.png';
import { useState } from 'react';



import {Await, Link, useNavigate} from "react-router-dom";
  
import './login.scss';
import { AuthContext } from '../../context/authContext';
import axios from 'axios';




const Login  = ()=>{

	const [inputs,setInputs]=useState({
		username:"",
		password:"",
	})
	const [error,setError] = useState(null)
	
	const navigation = useNavigate()
	const {login} =useContext(AuthContext);
	
	
	const handelChange = e =>{
		setInputs(prev =>({...prev,[e.target.name]:e.target.value}))
	
	}
	const handelSubmit= async (e)=>{
		e.preventDefault()
		   try{
			const loginResponse = await axios.post('/auth/login', inputs);

			await login(inputs)
			navigation("/dashboard/showarticle")
		}catch(error)
			 {
				 				setError(error.response?.data.error || "An error occurred");

			  }
	}
	
    return(
        <>
			
		
        <div className="auth ftco-section"  >
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section">
            <img src={logo} alt=""/>
          </h2>
				</div>
			</div>
			<div className="row justify-content-center">
        
				<div className="col-md-6 col-lg-5">
        <h3 className="text-center mb-4 text-white">welcome back to Bethought Dashboard</h3>

					<div className="login-wrap p-4 p-md-5">
		      	
						<form  className="login-form" method="post">
		      		<div className="form-group">
		      			<input type="text" className="form-control rounded-left"  autoComplete="username" placeholder="Username" name="username"   required onChange={handelChange}/>
		      		</div>
	            <div className="form-group d-flex">
	              <input type="password" className="form-control rounded-left" autoComplete="current-password" placeholder="Password" name="password"  required onChange={handelChange}/>
	            </div>
	            <div className="form-group d-md-flex">
	            	<div className="w-50 text-md-left">
						
						 	            		<Link to="/register" className="have_account checkbox-primary">don't have an account?
</Link>
						  
						
									 	
								</div>
								<div className="w-50 text-md-right">
								<Link to="/Forgot" >Forgot Password ?</Link>
								</div>
								
	            </div>
				<div className="w-50 text-md-left">
								{error && <p className='text-danger'>{error}</p>}
								</div>
	            <div className="form-group">
	            	<button onClick={handelSubmit} type="submit" name="submit" className="btn btn-primary rounded submit p-3 px-5">Get Started</button>
	            </div>
	          </form>
	        </div>
				</div>
			</div>
		</div>
	</div>
	
        </>
    )
}

export default Login;
