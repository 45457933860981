import React ,{useState}  from 'react';
import '../../dash.scss';
import './account.scss';
import {BsThreeDots} from 'react-icons/bs'
import {BiChevronRight} from 'react-icons/bi'
import {BiEdit} from 'react-icons/bi'
import {MdOutlineLightMode} from 'react-icons/md'
import {AiOutlineBars}  from 'react-icons/ai';
import {CiDark} from 'react-icons/ci'
//import admin from '../../../../../public/upload'
import { useContext } from 'react';
import { AuthContext } from '../../../../context/authContext';
import { Link } from 'react-router-dom';
const Accountside = ()=>{
  const {currentUser}= useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showIcon, setShowIcon] = useState(false); // Step 1: Add state for the icon
  //console.log(currentUser);
 // console.log(currentUser?.username);
  //console.log(`../../../../../public/upload/${currentUser?.img}`);


  const toggleAccount = () => {
    

    setShowSidebar(!showSidebar);
    setShowIcon(!showIcon); // Step 2: Toggle the icon's visibility
  };
    return(
     

        <> 
          {showIcon && (
             <div className="iconContainer">
        <AiOutlineBars className='linebare'onClick={toggleAccount} />
        </div>  )} 
 {currentUser? 
<div className={showSidebar ? 'divaccount' : 'divaccount hidden'}>

        
        
        <div className='account'>
            <div className="icons flex">
            <BiChevronRight className="icon" onClick={toggleAccount}/>
            <BsThreeDots className="icon"/>

            </div>
            <div className="accountDetails">
                <div className="imageDiv">
                    <img src={currentUser?.img} alt="account user image" />
                    <span className='name'>{currentUser?.username}</span>
                    <span className='position'>A reader</span>
                   
                </div>
                <Link className='btn flex' to={`/dashboard/profile/${currentUser?.id}`} >
                    <BiEdit className='icon' />
                    Edit Profile
                    </Link>
              {/**** 
                <div className="darkMode">
                    <span className="title">dark/ligth mood</span>
                    <div className="cardDark flex">
                        
                        <div className="left nobg">
                          <span><MdOutlineLightMode className='icon'/></span>  
                        </div>
                        <div className="right bg">
                            <span ><CiDark className='icon'/></span>
                        </div>
                    </div>
                </div>
              */}
            </div>
            </div>
            </div>
            : ""} 
</> 
        )
}

export default Accountside ;
